<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Reglas Operativas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Operación</li>
                  <li class="breadcrumb-item active">Reglas Operativas</li>
                </ol>
              </div>
            </div>
          </div>
          <!-- /.container-fluid -->
        </section>
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-10"></div>
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_tifreglas_export"
                        v-if="$store.getters.can('tif.reglasOperativas.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        data-toggle="modal"
                        data-target="#modal-form-reglas"
                        v-show="reglas != null"
                        @click="abrirModal('Crear', null)"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0 table-responsive">
                <table
                  id="reglas"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th class="text-center">
                        Bloque
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.bloque"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Tipo Novedad
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_novedad"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="tipo_novedad in listasForms.tipo_novedades"
                            :key="tipo_novedad.numeracion"
                            :value="tipo_novedad.numeracion"
                          >
                            {{ tipo_novedad.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Tipo Tarifa
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_tarifa"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="tipo_tarifa in listasForms.tipo_tarifas"
                            :key="tipo_tarifa.numeracion"
                            :value="tipo_tarifa.numeracion"
                          >
                            {{ tipo_tarifa.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center">Tiempo <sup>(minutos)</sup></th>
                      <th>
                        Causa Novedad
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.causa_novedad"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="causa_novedad in listasForms.causa_novedades"
                            :key="causa_novedad.numeracion"
                            :value="causa_novedad.numeracion"
                          >
                            {{ causa_novedad.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="regla in reglas.data" :key="regla.id">
                      <td>{{ regla.bloque.nombre }}</td>
                      <td>{{ regla.nTipoNovedad }}</td>
                      <td>{{ regla.nTipoTarifa }}</td>
                      <td>{{ regla.tiempo }}</td>
                      <td>{{ regla.nCausaNovedad }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-bind:class="[
                            regla.estado == 1
                              ? 'badge-success'
                              : 'badge-danger',
                          ]"
                        >
                          {{ regla.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                        </span>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#modal-form-reglas"
                            @click="abrirModal('Editar', regla.id)"
                            v-if="
                              $store.getters.can('tif.reglasOperativas.show')
                            "
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="
                              $store.getters.can('tif.reglasOperativas.delete')
                            "
                            @click="destroy(regla.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="reglas.total">
                  <p>
                    Mostrando del <b>{{ reglas.from }}</b> al
                    <b>{{ reglas.to }}</b> de un total:
                    <b>{{ reglas.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger"
                      >No hay registros para mostrar</span
                    >
                  </p>
                </div>
                <pagination
                  :data="reglas"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <ReglasOperativasExport ref="ReglasOperativasExport" />
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-reglas">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">{{ modal.title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
              id="close-modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group col-md-12">
              <label for="tipo_novedad">Tipo Novedad</label>
              <select
                id="tipo_novedad"
                class="form-control form-control-sm"
                v-model="objRegla.tipo_novedad"
                :class="
                  $v.objRegla.tipo_novedad.$invalid ? 'is-invalid' : 'is-valid'
                "
                @change="formRepetido()"
              >
                <option value="" disabled selected>Seleccione...</option>
                <option
                  v-for="tipo_novedad in listasForms.tipo_novedades"
                  :key="tipo_novedad.numeracion"
                  :value="tipo_novedad.numeracion"
                >
                  {{ tipo_novedad.descripcion }}
                </option>
              </select>
              <div class="error" v-if="!$v.objRegla.tipo_novedad.required">
                Ingrese el Tipo de Novedad
              </div>
            </div>
            <div class="form-group col-md-12">
              <label for="bloque">Bloque</label>
              <v-select
                :class="[
                  $v.objRegla.bloque_id.$invalid ? 'is-invalid' : 'is-valid',
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                ]"
                v-model="bloque"
                placeholder="Bloques"
                label="nombre"
                :options="listasForms.bloques"
                class="form-control form-control-sm p-0"
                @input="getSelectBloques()"
                @change="formRepetido()"
              ></v-select>
              <div class="error" v-if="!$v.objRegla.bloque_id.required">
                Ingrese el Bloque
              </div>
            </div>
            <div class="form-group col-md-12">
              <label>Tipo Tarifa</label>
              <select
                class="form-control form-control-sm"
                v-model="objRegla.tipo_tarifa"
                :class="
                  $v.objRegla.tipo_tarifa.$invalid ? 'is-invalid' : 'is-valid'
                "
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="tarifa in listasForms.tipo_tarifas"
                  :key="tarifa.numeracion"
                  :value="tarifa.numeracion"
                >
                  {{ tarifa.descripcion }}
                </option>
              </select>
              <div class="error" v-if="!$v.objRegla.tipo_tarifa.required">
                Ingrese Tipo Tarifa
              </div>
            </div>
            <div class="form-group col-md-12">
              <label for="tiempo">Tiempo<sup>(minutos)</sup></label>
              <input
                v-model="objRegla.tiempo"
                type="number"
                class="form-control form-control-sm col-md-12"
                style="font-size: 13px"
                :class="$v.objRegla.tiempo.$invalid ? 'is-invalid' : 'is-valid'"
              />
              <div class="error" v-if="!$v.objRegla.tiempo.required">
                Ingrese el Tiempo
              </div>
            </div>
            <div class="form-group col-md-12">
              <label for="causa_novedad">Causa Novedad</label>
              <select
                id="causa_novedad"
                class="form-control form-control-sm"
                v-model="objRegla.causa_novedad"
                :class="
                  $v.objRegla.causa_novedad.$invalid ? 'is-invalid' : 'is-valid'
                "
                @change="formRepetido()"
              >
                <option value="" disabled selected>Seleccione...</option>
                <option
                  v-for="causa_novedad in listasForms.causa_novedades"
                  :key="causa_novedad.numeracion"
                  :value="causa_novedad.numeracion"
                >
                  {{ causa_novedad.descripcion }}
                </option>
              </select>
              <div class="error" v-if="!$v.objRegla.causa_novedad.required">
                Seleccione la Causa de la Novedad
              </div>
            </div>
            <div class="form-group col-md-12">
              <label>Estado</label>
              <select
                class="form-control form-control-sm"
                v-model="objRegla.estado"
                :class="$v.objRegla.estado.$invalid ? 'is-invalid' : 'is-valid'"
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="estado in listasForms.estados"
                  :key="estado.numeracion"
                  :value="estado.numeracion"
                >
                  {{ estado.descripcion }}
                </option>
              </select>
              <div class="error" v-if="!$v.objRegla.estado.required">
                Seleccione el Estado
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="save()"
              v-show="
                !$v.objRegla.$invalid &&
                  ($store.getters.can('tif.reglasOperativas.create') ||
                    $store.getters.can('tif.reglasOperativas.show'))
              "
            >
              Guardar
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import ReglasOperativasExport from "./ReglasOperativasExport";

export default {
  name: "ReglasOperativasIndex",
  components: {
    pagination,
    vSelect,
    Loading,
    ReglasOperativasExport,
  },
  data() {
    return {
      bloque: [],
      filtros: {
        bloque: null,
        tipo_novedad: null,
        tipo_tarifa: null,
        causa_novedad: null,
        estado: null,
      },
      reglas: {},
      objRegla: {
        bloque_id: null,
      },
      listasForms: {
        bloques: [],
        estados: [],
        tipo_novedades: [],
        causa_novedades: [],
        tipo_tarifas: [],
      },
      modal: {
        title: "",
        accion: "",
      },
      cargando: false,
    };
  },
  validations() {
    return {
      objRegla: {
        bloque_id: {
          required,
        },
        tipo_novedad: {
          required,
        },
        tiempo: {
          required,
        },
        causa_novedad: {
          required,
        },
        tipo_tarifa: {
          required,
        },
        estado: {
          required,
        },
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/tif/reglasOperativas?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.reglas = response.data;
          this.cargando = false;
        });
    },

    getCausaNovedad() {
      axios.get("/api/lista/104").then((response) => {
        this.listasForms.causa_novedades = response.data;
      });
    },

    getTipoNovedad() {
      axios.get("/api/lista/105").then((response) => {
        this.listasForms.tipo_novedades = response.data;
      });
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTipoTarifa() {
      axios.get("/api/lista/96").then((response) => {
        this.listasForms.tipo_tarifas = response.data;
      });
    },

    datosModal(regla) {
      this.form.id = regla.id;
      this.form.estado = regla.estado;
    },

    getSelectBloques() {
      this.objRegla.bloque_id = null;
      if (this.bloque) {
        this.objRegla.bloque_id = this.bloque.id;
      }
    },

    abrirModal(accion, idReglas) {
      this.getCausaNovedad();
      this.getTipoNovedad();
      this.getEstados();
      this.getBloques();
      this.getTipoTarifa();
      this.modal.accion = accion;
      this.modal.title = accion + " Regla Operativa";
      if (accion == "Editar") {
        this.show(idReglas);
      } else {
        this.objRegla = { bloque_id: null };
        this.bloque = [];
      }
    },

    show(idReglas) {
      axios
        .get("/api/tif/reglasOperativas/show/" + idReglas)
        .then((response) => {
          this.objRegla = response.data;
          this.bloque = {
            id: this.objRegla.bloque_id,
            nombre: this.objRegla.bloque.nombre,
          };

          this.objRegla.bloque_id = this.bloque.id;
        });
    },

    save() {
      if (!this.$v.objRegla.$invalid) {
        axios
          .put("/api/tif/reglasOperativas", this.objRegla)
          .then((response) => {
            this.$refs.closeModal.click();
            this.objRegla = {};
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se actualizó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vualeva a intentarlo... " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroy(idReglas) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/tif/reglasOperativas/" + idReglas)
            .then((response) => {
              this.cargando = false;
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino el item exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    back() {
      this.$refs.closeModal.click();
    },

    formRepetido() {
      const form = this.objRegla;
      if (form.bloque_id && form.tipo_novedad && form.causa_novedad) {
        const data = this.reglas.data;
        data.forEach((operativas) => {
          if (
            form.bloque_id == operativas.bloque_id &&
            form.tipo_novedad == operativas.tipo_novedad &&
            form.causa_novedad == operativas.causa_novedad
          ) {
            this.resetForm();
            this.$swal({
              icon: "error",
              title: `Ya existe un registro con los mismos datos ingresados.`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return false;
          }
        });
      }
    },

    resetForm() {
      this.objRegla = {
        bloque_id: null,
        tipo_novedad: null,
        causa_novedad: null,
        tiempo: null,
        estado: null,
      };
      this.bloque = {};
    },
  },
  mounted() {
    this.getIndex();
    this.getCausaNovedad();
    this.getTipoNovedad();
    this.getBloques();
    this.getEstados();
    this.getTipoTarifa();
  },
};
</script>
